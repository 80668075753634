import React, { useEffect, useState, useRef } from "react";
import "./left.css";

const AdsLeft = () => {
  const [ad, setAd] = useState(null);
  const adRef = useRef(null);

  useEffect(() => {
    // API'den reklam verilerini çek
    fetch("https://api.psmmag.com/api/ads")
      .then((response) => response.json())
      .then((data) => {
        // "Sol DİKEY" içeren ve aktif olan reklamları filtrele
        const verticalAd = data.find(
          (ad) => ad.title.includes("Sol DİKEY") && ad.isActive === true
        );
        setAd(verticalAd);
      })
      .catch((error) => {
        console.error("Reklam verileri çekilirken hata oluştu:", error);
      });
  }, []);

  useEffect(() => {
    if (ad && adRef.current) {
      adRef.current.innerHTML = ""; // Önceki içeriği temizle

      let adURL = ad.imageURL;

      if (adURL.includes("doubleclick.net")) {
        // DoubleClick reklamı için boyutu güncelle
        adURL = adURL.replace(/sz=\d+x\d+/, `sz=160x650`);
      }

      if (
        adURL.includes("doubleclick.net") ||
        adURL.includes("sadbundle")
      ) {
        const iframe = document.createElement("iframe");
        iframe.src = adURL;
        iframe.width = "160px";
        iframe.height = "650px";
        iframe.frameBorder = "0";
        iframe.scrolling = "no";
        iframe.style = "border: 0px; vertical-align: bottom; margin-top: -10px;";
        if (adURL.includes("sadbundle")) {
          iframe.allowFullscreen = true;
        }
        adRef.current.appendChild(iframe);
      } else {
        const img = document.createElement("img");
        img.src = adURL;
        img.alt = ad.title;
        img.style = "width: 160px; height: 650px; object-fit: contain;";
        adRef.current.appendChild(img);
      }
    }
  }, [ad]);

  const handleAdClick = async () => {
    if (ad && ad._id) {
      try {
        await fetch(`https://api.psmmag.com/api/ads/${ad._id}/hit`, {
          method: "POST",
        });
      } catch (error) {
        console.error("Hit sayısı artırılırken hata oluştu:", error);
      }
    }
  };

  if (!ad) {
    return null; // Veya bir yükleme göstergesi
  }

  return (
    <div className="vertical-fixed-left adss">
      <a
        href={ad.link}
        target="_blank"
        rel="noopener noreferrer"
        className="ad-container"
        onClick={handleAdClick}
        style={{ display: 'block', width: '160px', height: '650px' }}
      >
        <div ref={adRef} style={{ width: '100%', height: '100%', marginTop: '-25px' }}>
          {/* Reklam içeriği buraya dinamik olarak eklenecek */}
        </div>
      </a>
    </div>
  );
};

export default AdsLeft;